@font-face {
  font-family: "font-1";
  src: url("../../public/assets/fonts/NunitoSans-Light.ttf");
}

* {
  padding: 0;
  margin: 0;
}
body {
  width: 100vw;
  overflow-x: hidden;
}
.body {
  position: relative;
  background: #1b1b1b;
  color: #ffffff;

  font-family: "font-1", serif;
  padding-bottom: 150px;
}

header {
  padding: 30px 0 30px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header h1 {
  font-size: 2.5em;
}

h1 {
  font-size: 2em;
  text-align: center;
  text-shadow: #f7ff3c 0px 0px 5px;
  margin-bottom: 30px;
}

main {
  width: 80vw;
  padding: 30px;
  min-height: 500px;

  box-shadow: #f7ff3c 0px 0px 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin: 0 auto;
}

.nav {
  width: 80vw;
  box-shadow: #f7ff3c 0px 0px 20px;
  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.nav ul {
  display: flex;
}

.nav ul a {
  text-decoration: none;
  color: #ffffff;
}
.nav li {
  margin: 20px;
  list-style: none;
  font-size: 1, 2em;
  font-weight: bold;
}

.nav .selected {
  text-shadow: #f7ff3c 0px 0px 5px;
  text-decoration: underline;
}

footer {
  position: absolute;
  padding: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: #f7ff3c 0px 0px 10px;
}

.footer_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

footer a:hover {
  color: #f7ff3c;
}

.theme {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: bold;
  cursor: pointer;
}

.themeicon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

 /* ************** RESPONSIVE ************** */

@media screen and (max-width: 650px) {
  h1 {
      margin: 0 50px;
  }
}

@media screen and (max-width: 700px) {
  .gifs_container {
      justify-content: center !important;
  }
  .footer_container{
    flex-direction: column;
    align-items: center;
  }
}