.gifs_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.gif_container{
    position: relative;
    overflow: hidden;
    width: 270px;
    margin: 10px;
}

.gif{
    width: 270px;
    object-fit: cover;
}

.favorite{
    position: absolute;
    top: 0;
    left: 0;
    color: #F7FF3C;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.search input{
    border-radius: 5px;
    border: 1px solid #F7FF3C;
    padding: 5px;
}

.search input[type=text]{
    width: 75%;
    background-color: #1b1b1b;
    color: #FFFFFF;
}

.search input[type=submit]{
    width: 20%;
}

.reload{
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: #F7FF3C;
    margin-bottom: 30px;
}